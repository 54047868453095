
import { defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import TmFormLine from '@/components/shared/TmFormLine.vue'
import SharingSelect from '@/components/shared/templates/SharingSelect.vue'
import { editorBtns } from '@/definitions/shared/editor/data'

export default defineComponent({
  components: {
    SharingSelect,
    TmModal,
    TmButton,
    TmFormLine,
  },
  props: {
    btnText: {
      type: String,
      default: 'Create',
    },
    modalTitle: {
      type: String,
      default: 'Create canned response',
    },
    initTitle: {
      type: String,
    },
    initResponse: {
      type: String,
    },
  },
  setup(props) {
    const title = ref(props.initTitle)
    const response = ref(props.initResponse)
    const editorButtons = [
      editorBtns.text,
      editorBtns.emoji,
      editorBtns.tags,
    ]

    return {
      title,
      editorButtons,
      response,
    }
  },
})
